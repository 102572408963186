import * as React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { Color } from "./designToken"
import css from "styled-jsx/css"
import "ress/ress.css" // apply base reset-css

const resetStyle = css.global`
  a,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
`

interface Props {
  title?: string
  children: any
}

const Layout = (props: Props) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `)
  const pageTitle = props.title
    ? `${props.title} | ${site.siteMetadata.title}`
    : site.siteMetadata.title
  return (
    <>
      <style jsx global>
        {resetStyle}
      </style>
      <style jsx global>{`
        .wf-loading {
          visibility: hidden;
        }
        html {
          font-size: 62.5%;
          font-size: 10px;
        }
        body {
          font-family: Metric, "Noto Sans JP", -apple-system, BlinkMacSystemFont,
            "Helvetica Neue", "游ゴシック体", YuGothic,
            "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial,
            "メイリオ", Meiryo, sans-serif;

          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-rendering: optimizeLegibility;
          font-feature-settings: "palt";
          background: ${Color.white};
        }
        strong,
        h1,
        h2,
        h3,
        h4 {
          font-weight: 500;
        }

        body main {
          width: 100%;
          position: relative;

          color: ${Color.defaultText};
          font-size: 1.8rem;
          font-weight: normal;
        }

        *::selection {
          color: ${Color.white};
          background: ${Color.corporate};
        }

        @font-face {
          font-family: "Metric";
          font-style: normal;
          font-weight: normal;
          src: url("/fonts/metric-web-regular.woff2") format("woff2"),
            url("/fonts/metric-web-regular.woff") format("woff");
        }
        @font-face {
          font-family: "Metric";
          font-style: normal;
          font-weight: bold;
          src: url("/fonts/metric-web-medium.woff2") format("woff2"),
            url("/fonts/metric-web-medium.woff") format("woff");
        }
        @font-face {
          font-family: "Metric";
          font-style: normal;
          font-weight: 500;
          src: url("/fonts/metric-web-medium.woff2") format("woff2"),
            url("/fonts/metric-web-medium.woff") format("woff");
        }
      `}</style>
      <Helmet
        htmlAttributes={{
          lang: "ja",
        }}
        title={pageTitle}
        meta={[
          {
            name: `description`,
            content: site.siteMetadata.description,
          },
          {
            property: `og:title`,
            content: site.siteMetadata.title,
          },
          {
            property: `og:description`,
            content: site.siteMetadata.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: "https://refcome.design/ogp.png",
          },
          {
            property: `fb:app_id`,
            content: `2220166827995808`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: site.siteMetadata.title,
          },
          {
            name: `twitter:description`,
            content: site.siteMetadata.description,
          },
          {
            name: `twitter:image`,
            content: "https://refcome.design/ogp.png",
          },
        ]}
      >
        <link
          rel="icon"
          type="image/png"
          href={require("../images/favicon.png")}
        />
        <link
          rel="apple-touch-icon"
          href={require("../images/apple-touch-icon.png")}
        />
      </Helmet>
      <main>{props.children}</main>
    </>
  )
}
export default Layout
