const Pallet = {
  white: "#ffffff",
  black: "#423f3c",
  gray: "#908B88",
  ashGray: "#fafafa",
  lightGray: "#f9f8f7",
  orange: "#ff7400",
}
const Semantics = {
  defaultText: Pallet.black,
  subText: Pallet.gray,
  defaultBg: Pallet.ashGray,
  corporate: Pallet.orange,
}
export const Color = Object.assign(Pallet, Semantics)
